import { createSlice } from "@reduxjs/toolkit";
import hit from "../services/manager";


const initialState={
    authorize:false,
    user:null
}

const authSlice=createSlice({
    name:"auth",
    initialState:initialState,
    reducers:{
        setAuthorize:(state,action)=>{
            state.authorize=action.payload
        },
        setUser:(state,action)=>{
            state.user=action.payload
        },
        clearAuthData:(state,action)=>{
            return initialState
        }
    }
})

export const {setAuthorize,setUser,clearAuthData}=authSlice.actions


export const getUserDataThunk=()=>{
    return async(dispatch)=>{
        try{
            const res= await hit("/users/self","get")
            if(!res.err){
                dispatch(setUser(res.data))
            }
        }catch(err){
            
        }finally{

        }
    }
}

export default authSlice.reducer
// prod
// export const base_url = "https://api.ourculturescore.com/"
// export const image_base_url = "https://d1jfvl8f1yx3kr.cloudfront.net/"
// port_live :5078


// dev
// export const base_url = "https://apidev.ourculturescore.com/v1/"
// export const image_base_url = "https://d21yobxcxp20i3.cloudfront.net/"


// stage api base url
// export const base_url = "https://apistage.ourculturescore.com/v1/"
// export const image_base_url = "https://d21yobxcxp20i3.cloudfront.net/"

var port = ''
var base_url = ''
var image_base_url = ''
var mode = "Dev"
if (mode == "Stage") {
    port = 5001
    base_url = "https://apidev.ourculturescore.com/v1/"
    image_base_url = "https://d21yobxcxp20i3.cloudfront.net/"
} else if (mode == "Stage") {
    port = 5002
    base_url = "https://apistage.ourculturescore.com/v1/"
    image_base_url = "https://d21yobxcxp20i3.cloudfront.net/"
} else if (mode == "Production") {
    port = 5080
    base_url = "https://api.ourculturescore.com/"
    image_base_url = "https://d1jfvl8f1yx3kr.cloudfront.net/"
}
module.exports = {port, base_url,image_base_url }
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import { Box } from '@mui/material';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

export default function FilterDate({ open, setOpen ,onSubmit,onAll}) {
    const [value, onChange] = useState([new Date(), new Date()]);
    const [modalHeight,setModalHeight]=useState("100px")
    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Select Date Between
            </DialogTitle>
            <DialogContent sx={{minHeight:modalHeight,display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                <DateTimeRangePicker  onCalendarOpen={()=>{
setModalHeight("300px")
                }}
                onCalendarClose={()=>{
                    setModalHeight("100px")
                }}
                onClockOpen={()=>{
                    setModalHeight("300px")
                }} onClockClose={()=>{
                    setModalHeight("100px")
                }} onChange={onChange} value={value} />
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Button onClick={()=>{
                        setOpen(false)
                    }}>
                        Cancel
                    </Button>
                    <Button onClick={onAll}>
                        All
                    </Button>
                    <Button onClick={()=>onSubmit(value)}>Submit</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}